import React from "react";
import Tabs from "./Tab";
import Info from "./images/about.jpg";
function About() {
  const tabs = ["ABOUT US", "MISSION", "VISION"];
  const defaultTab = "ABOUT US";
  return (
    <>
      <section className="about" id="about">
        <div className="container">
          <div className="about-area">
            <div className="row">
              <div className="col-sm-6 left-col">
                <h2>SERVICE THAT LEADS</h2>
                <h2 className="blue mt-2">TO BETTER DIGITALIZATION</h2>
                <Tabs tabs={tabs} defaultTab={defaultTab} />
              </div>
              <div className="col-sm-6 right-col">
                <img src={Info} className="about" alt="about us" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
