import About from "./myComponents/About";
import FloatingBackToTopButton from "./myComponents/consts/BackToTopButton";
import Footer from "./myComponents/Footer";
import Hero from "./myComponents/Hero";
import Navbar from "./myComponents/consts/Navbar";
import Services from "./myComponents/Services";
import Team from "./myComponents/Team";

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <About />
      <Services />
      <Team />
      <Footer />
      <FloatingBackToTopButton />
    </>
  );
}

export default App;
