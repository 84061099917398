import React, { useState } from "react";

function Navbar() {
  const [show, setShow] = useState(false);
  return (
    <>
      <section className="navbar">
        <div className="container">
          <header>
            <h4 className="logo ">
              <span className="blue">Soft</span>ulas
            </h4>
            <nav>
              <ul className="links">
                <li>
                  <a href="/">home</a>
                </li>
                <li>
                  <a href="#about">about us</a>
                </li>
                <li>
                  <a href="#services">services</a>
                </li>
                <li>
                  <a href="#team">our team</a>
                </li>
                <button id="myBtn">contact us</button>
              </ul>

              {show ? (
                <ul className="toggle">
                  <li>
                    <a href="/" onClick={() => setShow(!show)}>
                      home
                    </a>
                  </li>
                  <li>
                    <a href="#about" onClick={() => setShow(!show)}>
                      about us
                    </a>
                  </li>
                  <li>
                    <a href="#services" onClick={() => setShow(!show)}>
                      services
                    </a>
                  </li>
                  <li>
                    <a href="#team" onClick={() => setShow(!show)}>
                      our team
                    </a>
                  </li>
                  <button id="myBtn" onClick={() => setShow(!show)}>
                    contact us
                  </button>
                </ul>
              ) : null}
            </nav>
            <i
              className="fa-solid fa-bars white menu"
              onClick={() => setShow(!show)}
            ></i>
          </header>
        </div>
      </section>
    </>
  );
}

export default Navbar;
