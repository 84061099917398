import React, { useState } from "react";

const Tabs = ({ tabs, defaultTab }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabContent = {
    "ABOUT US":
      "Welcome to Softulas - Empowering your digital journey since 2021. We specialize in Website, Software, Android & iOS app development, Graphics Design and Marketing. Partner with us for innovative solutions that drive success.",
    MISSION: "Our mission is to revolutionize businesses through innovative technology solutions. We aim to deliver exceptional software, apps, and digital experiences, blending creativity and expertise. With a customer-centric approach, we strive to be your trusted partner, empowering your growth and success in the digital world.",
    VISION: "Empowering a Connected Future, our Vision at Softulas. Driving Innovation, Transforming Businesses.",
  };

  return (
    <div className="mt-2">
      <div className="tablinks">
        {tabs.map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={activeTab === tab ? "active" : ""}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {tabs.map((tab) => (
          <div key={tab} className={activeTab === tab ? "active" : ""}>
            <p>{tabContent[activeTab]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
