import React from "react";
import Counter from "./Counter";
import Img from "./images/moo.png";

function Hero() {
  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="hero-area">
            <div className="row mt-4">
              <div className="col-sm-6 ">
                <h2 className="white">TAKE FIRST STEP</h2>
                <h2 className="mt-2">TOWARD DIGITALIZATION</h2>
                <p className="white">
                EMBRACE THE FUTURE OF TECH WITH OUR VERSATILE SOFTWARE SOLUTIONS
                </p>
                <button className="btn-pry mt-2">REGESTER</button>
                <button className="btn-sec mt-2">LEARN MORE</button>
                <div className="mt-4 flex ">
                  <div className="text-center coin mr-2">
                    <h3 className="white ">
                      <Counter endNumber={22} />
                    </h3>
                    <p className="white">Projects done</p>
                  </div>

                  <div className="text-center coin mr-2">
                    <h3 className="white ">
                      <Counter endNumber={12} />
                    </h3>
                    <p className="white">Happy Client</p>
                  </div>

                  <div className="text-center coin mr-2">
                    <h3 className="white ">
                      <Counter endNumber={100} />%
                    </h3>
                    <p className="white">Squre up</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 flex-center p-4">
                <img
                  src={Img}
                  alt="hero area"
                  className="hero-img"
                  width="430vh"
                  height={300}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
