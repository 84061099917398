import React from "react";
import Info from "./images/about2.jpg";
function Services() {
  return (
    <>
      <section className="about" id="services">
        <div className="container">
          <div className="about-area">
            <div className="row">
              <div className="col-sm-6 ">
                <img
                  src={Info}
                  className="about2"
                  alt="about us"
                  width="100%"
                />

                <div className="icon-box">
                  <div className="box">
                    <i className="far fa-folder-open  fa-2xl"></i>
                  </div>
                  <div className="box">
                    <i className="fas fa-laptop-code fa-2xl"></i>
                  </div>
                  <div className="box">
                    <i className="fas fa-hand-holding-usd fa-2xl"></i>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 ">
                <h2>WHAT WE DO</h2>
                <h2 className="blue mt-2">TO MAKE CHANGES</h2>
                <p className="mt-2">
                At Softulas, we drive transformative change with innovative solutions in Website Development, Software, Apps, Graphics and Marketing. Together, we unleash your business's full potential in the digital world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
